@keyframes circular-rotate {
    0% {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }
    100% {
      transform: rotate(360deg);
    }
}

.spinner-loader{
    animation: circular-rotate .3s infinite;
}