.table-header, .table-row{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border: 1px solid black;
}
.table-header div{
    text-align: center;
    width: 100%;
    border-right: 1px solid black;
    background-color: #dde6ff;
    font-weight: 500;
    padding: 2px 0;
}
.table-header div:last-child, .table-body .table-row div:last-child{
    border: none;
}
.table-body .table-row{
    margin-top: 10px;
    background-color: rgba(221, 230, 255, 0.3);
    align-items: center;
}

.table-body .table-row div{
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 40px;
    border-right: 1px solid black;
    font-weight: 500;
}
.table-body span{
    color: red;
}