// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';


// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}
.css-1m4jws-MuiTypography-root {
    font-family: 'Lexend', sans-serif;
    font-size: '14px';
    width: 200px;
    height: 18px;
}
.MuiFormLabel-root {
    font-family: 'Lexend', sans-serif;
        font-size: '14px';
}
.MuiFormLabel-root {
font-family: 'Lexend', sans-serif;
    font-size: '14px';
}
.MuiTypography-root  {
    font-family: 'Lexend', sans-serif;
        font-size: '14px';
}
.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 12px;
}

.MuiFormHelperText-root {
font-family: 'Lexend', sans-serif;
}
// .MuiTableBody-root tr td {

//     border: 2px solid #000000;
//     text-align: 'center';
// }

// .MuiTable-root thead tr th{
.headerTd{
    background-color: #DDE6FF;
    font-size: 14px;
    line-height: 17.5px;
    font-family: 'Lexend';
    font-weight: 600;
    padding: 8px 12px 8px 12px;
    border: 1px solid #000000;
    text-align: center;
}

// .MuiTable-root tbody tr td {
.rowTr {
    // background-color: #dde6ff;
    font-size: 14px !important;
    line-height: 17.5px;
    font-family: 'Lexend' !important;
    font-weight: 500 !important;
    // padding: 8px;
    padding-left: 8px;
    border: 1px solid #000000;
    text-align: center;
}
.rightHidBor{
    border-right: hidden;
    
}
.tablePadding{
padding: 15px;
}

.MuiTableBody-root{
    background-color: transparent;
}

.MuiList-root .Mui-selected::after{
    content: url('../images/shape.png');
        position: absolute;
        top: -45%;
        left: 100%;
        // width: 0;
        // height: 0;
        // border-style: solid;
        // border-width: 20px 15px 20px 5px;
        // /* You can adjust these values to change the size of the triangle */
        // border-radius: 20px;
        // border-color: transparent #eb812e transparent transparent;
        /* Change the color to set the triangle color */
}

// .MuiTable-root tbody tr{
//     border: 1px solid #000000 !important
// }

// .MuiTable-root tbody tr td:not(:last-child) {
//     border-right: 1px solid #000000 !important;
// }

// .MuiTable-root{
//     border-collapse: collapse;
// }



// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

.text-input fieldset{
    border-color: #000000;
    border-width: 2px;
    border-radius: 8px;
    font-family: 'Lexend';
    font-size: '24px';
}

.view-input div:before {
    border-bottom-color: #000000;
    border-bottom-width: 2px;
}


.text-design {

    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    /* Black Color */

    color: #000000;



}
.search-input fieldset {
    border-color: #cacaca;
    border-width: 1px;
    border-radius: 15px;
}

.hov:hover{
    cursor: pointer;
}
// .search-input div > input {
//     background-color:#DDE5FF;
//     border-radius: 15px;
// }

// .search-input div> fieldset {
//     color: orange
    
    
// }

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
