.headerTd:last-child, .rowTr:last-child{
    border-right: 1px solid black;
}

.rowTr{
    height: 50px;
}

tr:last-child .rowTr{
    border-bottom: 1px solid black !important;
}