thead tr th {
    align-self: center;
    text-align: center !important;
}

.expense-table{
    width: 100%;
}

.expense-table td,
th {
    text-align: center !important;
    border: 1px solid black !important;
    border-right: 0px !important;
}

.expense-table td:last-child,
th:last-child {
    border-right: 1px solid black !important;
}

.total-row td:last-child, .total-row td {
    border: 0px !important;
    border-right: 0px !important;
}

.expense-table thead {
    background-color: #dde6ff !important;
}

.expense-table tbody {
    background-color: #f3f6ff !important;
}

.expense-table tbody td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.vendor-tr:last-child td {
    border: 1px solid black !important;
}

.vendor-tr:last-child td {
    border: 1px solid black !important;
}

.form-error {
    border-color: red !important;
}

.category-choice {
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    overflow-y: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.45);
}
.payee-option {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    z-index: 2;
    gap: 5px;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.45);
    border-radius: 5px;
}
.dropdown-button {
    border: none;
    display: flex;
    justify-content: space-between;
    font-size: 20;
    background: white;
    transition: all 0.2s linear;
    padding: 10px 10px;
    border-radius: 5px;
}

.dropdown-button:hover {
    background-color: rgba(255, 166, 0, 0.485);
}

.payee-arrow {
    position: absolute;
    right: 0;
    height: 50px;
    border-width: 0px 0px 0px 2px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    background-color: transparent;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotation-icon {
    animation: rotation 0.3s infinite;
}
